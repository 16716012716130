@font-face {
    font-family: Montserrat;
    src: url("../assets/fonts/Montserrat.ttf") format("truetype");
}
@font-face {
    font-family: Roboto;
    src: url("../assets/fonts/Roboto.ttf") format("truetype");
}
body{
    margin: 0px;
    padding: 0px;
    font-family: Roboto!important;
}
@import 'bootstrap/dist/css/bootstrap.min.css';

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: #162C4E; 
}