.carousel {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  .carousel-inner {
    display: flex;
    align-items: center;
    height: inherit;
    width: 100%;

    .carousel-item {
      height: inherit;
    }
  }
}

//top banner section starts
.top-banner-wrapper {
  .left-banner-details {
    background: #eefff6;
    height: 600px;
    display: grid;
    place-content: center;
    padding: 0px 4rem 0rem 10rem;

    h5 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      color: #84bc41;
    }

    h2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 31px;
      line-height: 150%;
      color: #162c4e;

      span {
        color: #84bc41;
      }
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 30px;
      color: #162c4e;
    }

    .email-letter-wrap {
      position: relative;

      .form-control {
        background: #ffffff;
        border: 0.5px solid #e2e2e2;
        border-radius: 0px;
        line-height: 35px;

        &:focus {
          box-shadow: unset;
        }
      }

      .begin-btn-wrap {
        background: #002c72;
        position: absolute;
        top: 6px;
        right: 10px;
        border-radius: 0px;
        border-color: #002c72;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
    }
  }
}

//feature section style startsf
.common-title-wrap {
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: #84bc41;
    text-align: center;
    margin-bottom: 10px;
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 141%;
    color: #162c4e;
    text-align: center;
    margin-bottom: 30px;

    span {
      color: #84bc41;
    }
  }
}

.feature-section-wrapper {
  background-color: #fff;
  padding: 5rem;

  .custom-width {
    max-width: 1010px;
  }

  .feature-card-common {
    background: #f4fbfa;
    padding: 25px;
    height: 100%;

    img {
      width: 70px;
      height: 70px;
      margin-bottom: 20px;
    }

    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      font-size: 18px;
      color: #000000;
      height: 46px;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 200%;
      color: #000000;
      margin-bottom: 0px;
    }
  }

  .feature-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 6rem;

    .get-started-btn {
      font-family: Montserrat;
      background: #162c4e;
      line-height: 37px;
      padding: 5px 17px;
      border-color: #162c4e;
      font-size: 14px;
      border-radius: 0px;
      font-weight: 600;
      color: #fff;
    }

    .browse-btn {
      font-family: Montserrat;
      background: #e6eceb;
      line-height: 37px;
      padding: 5px 17px;
      border-color: #e6eceb;
      font-size: 14px;
      border-radius: 0px;
      font-weight: 600;
      color: #162c4e;
    }
  }
}

// product section starts
.product-sec-wrap {
  background: #162c4e;
  padding: 6rem 4rem;

  .product-left-image {
    img {
      width: 85%;
      height: auto;
    }
  }

  .product-right-details {
    .product-title {
      margin-bottom: 4rem;

      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #84bc41;
        margin-bottom: 10px;
      }

      h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 900;
        font-size: 27px;
        line-height: 141%;
        color: #ffffff;

        span {
          color: #84bc41;
        }
      }
    }

    .common-product-listing {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      img {
        width: 100px;
        height: 100px;
        margin-right: 15px;
      }

      .product-detail {
        h4 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 900;
          font-size: 22px;
          line-height: 141%;
          color: #ffffff;
          margin-bottom: 2px;
        }

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 200%;
          color: #ffffff;
          margin-bottom: 0px;
        }
      }
    }
  }
}

//getting started section starts
.get-started-section-wrap {
  background-color: #fff;
  padding: 4rem;
  .custom-width {
    max-width: 1010px;
  }
  .common-stared-cards {
    .image-top-sec {
      height: 275px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.yellow {
        background: #f9c416;
        img {
          height: 115px;
          width: 115px;
        }
      }

      &.sky-blue {
        background: #eefff6;
        img {
          height: 120px;
          width: 85px;
        }
      }

      &.green {
        background: #cde4b3;
        img {
          height: 90px;
          width: 150px;
        }
      }
    }
    .getting-started-details {
      padding: 0px 31px;
      img {
        display: block;
        width: 55px;
        height: 55px;
        margin: auto;
        margin-top: -34px;
        margin-bottom: 10px;
      }
      h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #162c4e;
        height: 70px;
      }

      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 200%;
        text-align: center;
        color: #162c4e;
        margin-bottom: 0px;
      }
    }
  }
  .started-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 6rem;

    .get-started-btn {
      font-family: Montserrat;
      background: #162c4e;
      line-height: 37px;
      padding: 5px 17px;
      border-color: #162c4e;
      font-size: 14px;
      border-radius: 0px;
      font-weight: 600;
      color: #fff;
    }
  }
}

//responsive starts
//for mobile devices
@media only screen and (min-width: 320px) and (max-width: 520px) {
  //top banner section style
  .top-banner-wrapper {
    .left-banner-details {
      padding: 0px 1rem 0rem 1rem;
      padding-top: 6em;
      height: 100%;
      .email-letter-wrap {
        .form-control {
          font-size: 13px;
        }
      }
    }
    .right-banner-detail {
      justify-content: center;
      padding: 0px 1rem 0rem 1rem;
      button {
        margin: 0;
      }
      .right-img-banner {
        width: 80%;
      }
    }
  }

  //feature section
  .feature-section-wrapper {
    padding: 5px;
    .feature-btns {
      gap: 11px;
      margin-top: 2rem;
      margin-bottom: 25px;
      flex-direction: column;
      .btn {
        width: 185px;
      }
    }
  }
  .common-title-wrap {
    padding: 16px 10px;
    h3 {
      margin-bottom: 0px;
      font-size: 21px;
    }
  }

  //product section
  .product-sec-wrap {
    padding: 3rem 1rem;
    .product-left-image {
      img {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
      }
    }
    .product-right-details {
      .common-product-listing {
        flex-direction: column;
        img {
          margin-right: 0px;
          margin-bottom: 15px;
        }
      }
    }
  }

  // get started section
  .get-started-section-wrap {
    padding: 3rem 0px;
    .custom-width {
      margin-top: 20px;
    }
    .common-stared-cards {
      .getting-started-details {
        h3 {
          height: unset;
        }
      }
    }
    .started-btns {
      margin-top: 3rem;
    }
  }

  //   footer style
  .footer-wrap {
    padding: 3rem 5px !important;
    padding-bottom: 1rem !important;
    .footer-listing {
      .email-us {
        padding: 4px 9px;
        img {
          width: 33px;
        }
      }
      .call-us {
        padding: 4px 9px !important;
        img {
          margin-left: 0px;
        }
      }
    }
    .copy-right-wrapper {
      flex-direction: column;
      gap: 15px;
      P {
        text-align: center;
        line-height: 20px;
      }
    }
  }
}

//medium deviecs
@media only screen and (min-width: 521px) and (max-width: 767px) {
  //top banner section style
  .top-banner-wrapper {
    .left-banner-details {
      padding: 0px 1rem 0rem 1rem;
      padding-top: 6em;
      height: 100%;
      .email-letter-wrap {
        .form-control {
          font-size: 13px;
        }
      }
    }
    .right-banner-detail {
      padding: 0px 1rem 0rem 1rem;
      justify-content: center;
      button {
        margin: 0;
      }
      .right-img-banner {
        width: 325px;
      }
    }
  }

  //feature section
  .feature-section-wrapper {
    padding: 5px;
    .feature-btns {
      gap: 11px;
      margin-top: 2rem;
      margin-bottom: 25px;
      flex-direction: column;
      .btn {
        width: 185px;
      }
    }
  }
  .common-title-wrap {
    padding: 16px 10px;
    h3 {
      margin-bottom: 0px;
      font-size: 21px;
    }
  }

  //product section
  .product-sec-wrap {
    padding: 3rem 1rem;
    .product-left-image {
      img {
        width: 60%;
        height: auto;
        margin-bottom: 15px;
      }
    }
    .product-right-details {
      .common-product-listing {
        flex-direction: column;
        align-items: baseline;
        img {
          margin-right: 0px;
          margin-bottom: 15px;
        }
      }
    }
  }

  // get started section
  .get-started-section-wrap {
    padding: 3rem 0px;
    .custom-width {
      margin-top: 20px;
    }
    .common-stared-cards {
      .getting-started-details {
        h3 {
          height: unset;
        }
      }
    }
    .started-btns {
      margin-top: 3rem;
    }
  }

  //   footer style
  .footer-wrap {
    padding: 3rem 5px !important;
    padding-bottom: 1rem !important;
    .footer-listing {
      .email-us {
        padding: 4px 9px;
        img {
          width: 33px;
        }
      }
      .call-us {
        padding: 4px 9px !important;
        img {
          margin-left: 0px;
        }
      }
    }
    .copy-right-wrapper {
      flex-direction: column;
      gap: 15px;
      P {
        text-align: center;
        line-height: 20px;
      }
    }
  }
}

@media only screen and (min-width: 527px) and (max-width: 767px) {
  .product-sec-wrap {
    .product-right-details {
      .common-product-listing {
        width: 50%;
        float: left;
      }
    }
  }
}
/*For Ipad or tablet*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  //top banner section style
  .top-banner-wrapper {
    .left-banner-details {
      padding: 0px 1rem 0rem 1rem;
      padding-top: 6em;
      height: 100%;
      .email-letter-wrap {
        .form-control {
          font-size: 13px;
        }
      }
    }
    .right-banner-detail {
      padding: 0px 1rem 0rem 1rem;
      button {
        margin: 0;
      }
      .right-img-banner {
        width: 300px;
      }
    }
  }

  //product section
  .product-sec-wrap {
    padding: 3rem 1rem;
    .product-left-image {
      img {
        width: 50%;
        height: auto;
        margin-bottom: 15px;
      }
    }
    .product-right-details {
      .common-product-listing {
        flex-direction: column;
        align-items: baseline;
        width: 33%;
        float: left;
        margin-bottom: 0px;
        img {
          margin-right: 0px;
          margin-bottom: 15px;
        }
      }
    }
  }
  // get started section
  .get-started-section-wrap {
    padding: 2rem;
    .custom-width {
      margin-top: 20px;
    }
    .common-stared-cards {
      .getting-started-details {
        h3 {
          height: unset;
        }
      }
    }
    .started-btns {
      margin-top: 3rem;
    }
  }
  //   footer style
  .footer-wrap {
    padding: 3rem 5px !important;
    padding-bottom: 1rem !important;
    .footer-listing {
      .email-us {
        padding: 4px 9px;
        img {
          width: 33px;
        }
      }
      .call-us {
        padding: 4px 9px !important;
        img {
          margin-left: 0px;
        }
      }
    }
    .copy-right-wrapper {
      P {
        text-align: center;
        line-height: 20px;
      }
    }
  }
}

//large devices
@media only screen and (min-width: 991px) and (max-width: 1250px) {
  //   footer style
  .footer-wrap {
    padding: 3rem 5px !important;
    padding-bottom: 1rem !important;
    .footer-listing {
      .email-us {
        padding: 4px 9px;
        img {
          width: 33px;
        }
      }
      .call-us {
        padding: 4px 9px !important;
        img {
          margin-left: 0px;
        }
      }
    }
    .copy-right-wrapper {
      P {
        text-align: center;
        line-height: 20px;
      }
    }
  }
  .footer-banner-wrap.home-footer-banner-bg {
    height: 100%;
  }

  // get started section
  .get-started-section-wrap {
    .custom-width {
      margin-top: 20px;
    }
    .common-stared-cards {
      .getting-started-details {
        h3 {
          height: unset;
        }
      }
    }
    .started-btns {
      margin-top: 3rem;
    }
  }
  //top banner section style
  .top-banner-wrapper {
    .left-banner-details {
      padding: 0px 1rem 0rem 1rem;
      padding-top: 6em;
      height: 100%;
      .email-letter-wrap {
        .form-control {
          font-size: 13px;
        }
      }
    }
    .right-banner-detail {
      padding: 0px 1rem 0rem 1rem;
      button {
        margin: 0;
      }
      .right-img-banner {
        width: 300px;
      }
    }
  }
}
