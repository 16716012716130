.about-us-banner {
  height: 100%;
  background: #eefff6;
  padding-top: 8rem;

  .title-wrap {
    padding: 0px 15rem;

    h5 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      color: #84bc41;
    }

    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 37px;
      line-height: 141%;
      color: #162c4e;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 200%;
      color: #000000;
      width: 60%;
    }

    .button-group-wrap {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 2rem;

      .join-team-btn {
        background: #162c4e;
        font-family: Montserrat;
        border: 0px;
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        color: #ffffff;
        line-height: 40px;
        padding: 2px 14px;
      }

      .company-btn {
        font-family: Montserrat;
        background: #ffffff;
        border: 0px;
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        line-height: 40px;
        padding: 2px 14px;
        color: #162c4e;
      }
    }
  }

  .banner-footer-img {
    margin-top: 40px;

    img {
      width: 950px;
      height: auto;
    }
  }
}

//our number section style starts
.number-wrapper {
  padding: 5rem;
  padding-bottom: 8rem;
  h5 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #84bc41;
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 29px;
    line-height: 141%;
    text-align: center;
    color: #162c4e;
  }

  .number-details {
    margin-top: 40px;
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 65px;
      line-height: 120%;
      text-align: center;
      color: #000000;
      margin-bottom: 0px;
      .blue {
        color: #099ec8;
      }
      .yellow {
        color: #f9c416;
      }
      .green {
        color: #37e8ce;
      }
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 141%;
      text-align: center;
      color: #000000;
      margin-bottom: 0px;
    }
  }
}

//about us story
.about-us-story {
  background: #f4fbfa;
  padding: 6rem 0px;
  .story-details-wrap {
    display: grid;
    place-content: center;
    height: 100%;
    h5 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      color: #84bc41;
    }
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 141%;
      color: #162c4e;
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 200%;
      color: #000000;
    }
  }
  .story-image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 70%;
      height: auto;
    }
  }
}

//our values
.our-value-wrap {
  padding: 6rem 8rem;
  .title-wrap {
    h5 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      color: #84bc41;
    }
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 27px;
      line-height: 141%;
      color: #000000;
    }
  }
  .value-card-wrap {
    background: #f4fbfa;
    padding: 25px;
    height: 100%;
    .card-image {
      width: 65px;
      height: 65px;
      img {
        width: 100%;
        height: auto;
      }
    }
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 21px;
      line-height: 141%;
      color: #000000;
      margin-top: 24px;
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 200%;
      color: #000000;
    }
  }
}

// our team
.our-team-wrap {
  background: #162c4e;
  padding: 5rem 13rem;
  .team-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    .title {
      h5 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: #84bc41;
      }
      h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 141%;
        color: #ffffff;
      }
    }
    .join-team-btn {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 41px;
      color: #162c4e;
      background: #ffffff;
      border-radius: 0px;
      border: 0px;
      padding: 0px 14px;
    }
  }
  .team-card-common {
    img {
      width: 100%;
      height: auto;
    }
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 23px;
      line-height: 141%;
      color: #ffffff;
      margin-top: 29px;
      display: flex;
      align-items: center;
      span {
        width: 60px;
        height: 4px;
        display: flex;
        background-color: #fff;
        margin-left: 20px;
      }
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 220%;
      color: #ffffff;
    }
  }
  .see-btn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 41px;
    color: #162c4e;
    background: #ffffff;
    border-radius: 0px;
    border: 0px;
    padding: 0px 14px;
  }
}

// our office section
.come-and-visit-on-wrapper {
  padding: 4rem 8rem;
  padding-bottom: 8rem;

  .come-and-vidit-left-side-img {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 50%;
      width: 395px;
      height: auto;
    }
  }

  .office-details {
    padding-top: 65px;
    width: 90%;

    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      line-height: 141%;
      color: #162c4e;
      margin-bottom: 2px;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 200%;
      color: #162c4e;
    }

    .phone-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 200%;
      color: #000000;
      margin-bottom: 15px;

      span {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .location-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 200%;
      color: #000000;
      margin-bottom: 15px;

      span {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .client-name {
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 25px;
      line-height: 141%;
      color: #b1c6cc;
      margin-bottom: 0px;
      margin-top: 29px;
    }
  }
}

// footer banner section starts
.footer-banner-wrap {
  padding: 3rem 10rem;

  &.other-common-banner {
    height: 100%;
    border-top: 1px solid #ccc;
    background: #162c4e;
    position: relative;
    padding: 24px 0px;

    .right-side-wrapper {
      img {
        width: 402px;
        height: auto;
        position: absolute;
        right: 0px;
        top: 0px;
      }
    }
  }

  .left-title-wrap {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    height: 355px;

    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 141%;
      color: #ffffff;
      margin-bottom: 40px;
    }

    .get-started-btn {
      font-family: Montserrat;
      background: #fff;
      line-height: 37px;
      padding: 5px 17px;
      border-color: #fff;
      font-size: 14px;
      border-radius: 0px;
      font-weight: 600;
      color: #162c4e;
    }
  }
}

//for mobile devices
@media only screen and (min-width: 320px) and (max-width: 520px) {
  //our office style
  .come-and-visit-on-wrapper {
    padding: 0px 1rem;
    padding-bottom: 3rem;

    .office-details {
      padding-top: 5px;
      width: 100%;
    }

    .come-and-vidit-left-side-img {
      img {
        width: 100%;
      }
    }
  }

  // banner style
  .about-us-banner {
    .banner-footer-img {
      img {
        width: 100%;
      }
    }
    .title-wrap {
      padding: 0px 1rem;
      .button-group-wrap {
        flex-direction: column;
      }
    }
  }

  // number style
  .number-wrapper {
    padding: 3rem 1rem;
  }

  //story wrap
  .about-us-story {
    .story-image {
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
  }
  .our-value-wrap {
    padding: 3rem 1rem;
  }
  .our-team-wrap {
    padding: 3rem 1rem;
    .team-title {
      flex-direction: column;
    }
  }
}

//medium deviecs
@media only screen and (min-width: 521px) and (max-width: 767px) {
  //our office style
  .come-and-visit-on-wrapper {
    padding: 0px 1rem;
    padding-bottom: 3rem;

    .office-details {
      padding-top: 5px;
      width: 100%;
    }

    .come-and-vidit-left-side-img {
      img {
        width: 100%;
      }
    }
  }

  // banner style
  .about-us-banner {
    .banner-footer-img {
      img {
        width: 100%;
      }
    }
    .title-wrap {
      padding: 0px 1rem;
      .button-group-wrap {
        flex-direction: column;
      }
    }
  }

  // number style
  .number-wrapper {
    padding: 3rem 1rem;
  }

  //story wrap
  .about-us-story {
    .story-image {
      margin-bottom: 20px;
      // img{
      //     width: 100%;
      // }
    }
  }
  .our-value-wrap {
    padding: 3rem 1rem;
  }
  .our-team-wrap {
    padding: 3rem 1rem;
    .team-title {
      flex-direction: column;
    }
  }
}

/*For Ipad or tablet*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  //our office style
  .come-and-visit-on-wrapper {
    padding: 3rem 2rem;
    padding-bottom: 4rem;

    .come-and-vidit-left-side-img {
      img {
        width: 100%;
      }
    }
  }

  // banner style
  .about-us-banner {
    .banner-footer-img {
      img {
        width: 100%;
      }
    }
    .title-wrap {
      padding: 0px 1rem;
    }
  }

  // number style
  .number-wrapper {
    padding: 3rem 1rem;
  }

  //story wrap
  .about-us-story {
    .story-image {
      margin-bottom: 20px;
    }
  }
  .our-value-wrap {
    padding: 3rem 1rem;
  }
  .our-team-wrap {
    padding: 3rem 1rem;
  }
}

//large devices
@media only screen and (min-width: 991px) and (max-width: 1250px) {
}
