//common title style
.common-title-wrap {
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: #84bc41;
    text-align: center;
    margin-bottom: 10px;
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 141%;
    color: #162c4e;
    text-align: center;
    margin-bottom: 30px;

    span {
      color: #84bc41;
    }
  }
}

// Get in Touch wrapper

.get-in-touch-wrapper {
  place-content: center;
  display: block;
  margin: auto;
  background: #ffffff;
  margin-top: 9rem !important;

  .form-main {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    padding: 1rem 3rem;
    padding-top: 3rem !important;
    background-color: white;
    width: 50%;
    margin: auto;
    position: relative;
    top: -70px;

    .form-control {
      background: #ffffff;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
      border: none;
      border-radius: 0%;
      width: 100%;
      height: 54px;
    }

    textarea.form-control {
      background: #ffffff;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
      border: none;
      border-radius: 0%;
      height: 123px;
      width: 100%;
    }

    .form-label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 200%;
      color: #000000;
    }

    .send-msg-btn {
      background: #162c4e;
      border-radius: 0%;
      border-color: #162c4e;
      padding: 10px 23px;
      font-weight: 500;
      transition: all 0.4s ease;
      color: #ffffff;
    }

    .send-msg-btn:hover {
      background-color: white;
      color: #000000;
      border: 1px solid #000000;
    }

    &::before {
      content: "";
      position: absolute;
      background-image: url(./../../assets/images/contact-left.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      top: 0px;
      left: -240px;
      width: 240px;
      height: 497px;
    }

    &::after {
      content: "";
      position: absolute;
      background-image: url(./../../assets/images/contact-right.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      top: 0px;
      right: -240px;
      width: 240px;
      height: 497px;
    }
  }

  .contactus-form {
    background-image: url(../../assets/images/contant-background.png);
    height: 426px;
    margin-top: 7rem;
  }
}

// come and visit section
.come-and-visit-on-wrapper {
  padding: 0px 8rem;
  padding-bottom: 8rem;
  &.custom-padding {
    padding-top: 0px !important;
  }
  .green-circular {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    img {
      width: 305px;
      height: auto;
    }
  }

  .come-and-vidit-left-side-img {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 50%;
      width: 395px;
      height: auto;
    }
  }

  .office-details {
    padding-top: 65px;
    width: 90%;

    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      line-height: 141%;
      color: #162c4e;
      margin-bottom: 2px;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 200%;
      color: #162c4e;
    }

    .phone-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 200%;
      color: #000000;
      margin-bottom: 15px;

      span {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .location-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 200%;
      color: #000000;
      margin-bottom: 15px;

      span {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .client-name {
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 25px;
      line-height: 141%;
      color: #b1c6cc;
      margin-bottom: 0px;
      margin-top: 29px;
    }
  }
}

//ask question
.freq-ask-question {
  background: #162c4e;
  padding: 6rem;

  .common-title-wrap {
    h3 {
      color: #fff;
    }
  }

  .accordion {
    .accordion-item {
      border-radius: 0px;

      .accordion-header {
        .accordion-button {
          background-color: transparent;
          box-shadow: unset;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 200%;
          text-align: center;
          color: #162c4e;
          padding: 11px;
        }
      }
    }
  }
}

// footer banner section starts
.footer-banner-wrap {
  padding: 3rem 10rem;

  &.other-common-banner {
    height: 100%;
    border-top: 1px solid #ccc;
    background: #162c4e;
    position: relative;
    padding: 24px 0px;

    .right-side-wrapper {
      img {
        width: 402px;
        height: auto;
        position: absolute;
        right: 0px;
        top: 0px;
      }
    }
  }

  .left-title-wrap {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    height: 355px;

    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 141%;
      color: #ffffff;
      margin-bottom: 40px;
    }

    .get-started-btn {
      font-family: Montserrat;
      background: #fff;
      line-height: 37px;
      padding: 5px 17px;
      border-color: #fff;
      font-size: 14px;
      border-radius: 0px;
      font-weight: 600;
      color: #162c4e;
    }
  }
}

.input-number {
  flex-grow: 1;
}

.select-country {
  max-width: 90px;
  width: fit-content;
}

//for mobile devices
@media only screen and (min-width: 320px) and (max-width: 520px) {
  .get-in-touch-wrapper {
    margin-top: 5rem !important;

    .contactus-form {
      padding: 10px 0px;
      height: auto;
      margin-top: 0px;
    }

    .form-main {
      width: 92%;
      padding: 1rem;
      top: unset;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }

  .come-and-visit-on-wrapper {
    padding: 0px 1rem;
    padding-bottom: 3rem;

    .office-details {
      padding-top: 5px;
      width: 100%;
    }

    .come-and-vidit-left-side-img {
      img {
        width: 100%;
      }
    }
  }

  .freq-ask-question {
    padding: 0rem;
  }

  .footer-banner-wrap {
    &.other-common-banner {
      .right-side-wrapper {
        img {
          position: relative !important;
          width: 100% !important;
          right: -12px !important;
        }
      }
    }
  }
}

//medium deviecs
@media only screen and (min-width: 521px) and (max-width: 767px) {
  .get-in-touch-wrapper {
    margin-top: 5rem !important;

    .contactus-form {
      padding: 30px 0px;
      height: auto;
      margin-top: 0px;
    }

    .form-main {
      width: 80%;
      padding: 1rem;
      top: unset;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }

  .come-and-visit-on-wrapper {
    padding: 0px 1rem;
    padding-bottom: 3rem;

    .office-details {
      padding-top: 5px;
      width: 100%;
    }

    .come-and-vidit-left-side-img {
      img {
        width: 100%;
      }
    }
  }

  .freq-ask-question {
    padding: 0rem;
  }

  .footer-banner-wrap {
    &.other-common-banner {
      .right-side-wrapper {
        img {
          position: relative !important;
          width: 70% !important;
          right: -12px !important;
        }
      }
    }
  }
}

/*For Ipad or tablet*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  //get in touch wrap
  .get-in-touch-wrapper {
    .form-main {
      width: 65%;

      &::before {
        width: 105px;
        height: 200px;
        left: -105px;
      }

      &::after {
        width: 117px;
        height: 230px;
        right: -117px;
      }
    }
  }

  //come visit section
  .come-and-visit-on-wrapper {
    padding: 0px 2rem;
    padding-bottom: 4rem;

    .come-and-vidit-left-side-img {
      img {
        width: 100%;
      }
    }
  }

  //faq question
  .freq-ask-question {
    padding: 2rem;
  }
}

//large devices
@media only screen and (min-width: 991px) and (max-width: 1250px) {
}
