//common footer style starts
.footer-wrap {
  background-color: #84bc41;
  padding: 5rem 10rem;
  padding-bottom: 10px;
  .footer-listing {
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 17px;
      line-height: 200%;
      color: #ffffff;
      margin-bottom: 20px;
    }

    ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;

      li {
        list-style-type: none;
        text-decoration: none;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 200%;
        color: #ffffff;
        margin-bottom: 8px;
        cursor: pointer;
        &:hover {
          color: #162c4e;
        }
      }
    }
    &.social-icon {
      ul {
        li {
          span {
            img {
              background-color: #fff;
              margin-right: 6px;
              margin-top: -3px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    .email-us {
      padding: 10px 12px;
      display: flex;
      align-items: center;
      background: #94c55b;
      margin-bottom: 20px;
      img {
        width: 50px;
        height: auto;
      }
      .email-detail {
        margin-left: 15px;
        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 200%;
          color: #ffffff;
          margin-bottom: 0px;
        }
        h3 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 900;
          font-size: 15px;
          line-height: 200%;
          color: #ffffff;
          margin-bottom: 0px;

          span {
            img {
              width: 19px;
              height: auto;
              top: -3px;
              left: 6px;
              position: relative;
            }
          }
        }
      }
    }
    .call-us {
      padding: 10px 12px;
      display: flex;
      align-items: center;
      background: #94c55b;
      margin-bottom: 20px;
      img {
        width: 31px;
        height: auto;
        margin-left: 13px;
      }
      .email-detail {
        margin-left: 15px;
        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 200%;
          color: #ffffff;
          margin-bottom: 0px;
        }
        h3 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 900;
          font-size: 15px;
          line-height: 200%;
          color: #ffffff;
          margin-bottom: 0px;

          span {
            img {
              width: 19px;
              height: auto;
              top: -3px;
              left: 6px;
              position: relative;
            }
          }
        }
      }
    }
  }
  .copy-right-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #fff;
    margin-top: 40px;
    padding: 19px 0px;
    img {
      width: 100px;
      height: auto;
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 200%;
      text-align: right;
      color: #ffffff;
      margin-bottom: 0px;
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}

//for mobile devices
@media only screen and (min-width: 320px) and (max-width: 520px) {
  //   footer style
  .footer-wrap {
    padding: 3rem 5px !important;
    padding-bottom: 1rem !important;
    .footer-listing {
      .email-us {
        padding: 4px 9px;
        img {
          width: 33px;
        }
      }
      .call-us {
        padding: 4px 9px !important;
        img {
          margin-left: 0px;
        }
      }
    }
    .copy-right-wrapper {
      flex-direction: column;
      gap: 15px;
      P {
        text-align: center;
        line-height: 20px;
      }
    }
  }
  .footer-banner-wrap {
    padding: 3rem 10px;
    &.home-footer-banner-bg {
      background-size: cover;
      height: 100%;
    }
    .left-title-wrap {
      h3 {
        font-size: 27px;
      }
    }
    .right-side-wrapper {
      img {
        width: 80%;
        margin: auto;
        display: block;
      }
    }
  }
}

//medium deviecs
@media only screen and (min-width: 521px) and (max-width: 767px) {
  //   footer style
  .footer-wrap {
    padding: 3rem 5px !important;
    padding-bottom: 1rem !important;
    .footer-listing {
      .email-us {
        padding: 4px 9px;
        img {
          width: 33px;
        }
      }
      .call-us {
        padding: 4px 9px !important;
        img {
          margin-left: 0px;
        }
      }
    }
    .copy-right-wrapper {
      flex-direction: column;
      gap: 15px;
      P {
        text-align: center;
        line-height: 20px;
      }
    }
  }
  .footer-banner-wrap {
    padding: 3rem 10px;
    &.home-footer-banner-bg {
      background-size: cover;
      height: 100%;
    }
    .left-title-wrap {
      h3 {
        font-size: 27px;
      }
    }
    .right-side-wrapper {
      img {
        width: 100%;
        display: block;
        margin-left: auto;
      }
    }
  }
}

/*For Ipad or tablet*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  //   footer style
  .footer-wrap {
    padding: 3rem 5px !important;
    padding-bottom: 1rem !important;
    .footer-listing {
      .email-us {
        padding: 4px 9px;
        img {
          width: 33px;
        }
      }
      .call-us {
        padding: 4px 9px !important;
        img {
          margin-left: 0px;
        }
      }
    }
    .copy-right-wrapper {
      P {
        text-align: center;
        line-height: 20px;
      }
    }
  }
  .footer-banner-wrap {
    padding: 3rem 10px;
    &.home-footer-banner-bg {
      background-size: cover;
      height: 100%;
    }
    .left-title-wrap {
      h3 {
        font-size: 27px;
      }
    }
    .right-side-wrapper {
      img {
        width: 80%;
      }
    }
  }
}

//large devices
@media only screen and (min-width: 991px) and (max-width: 1250px) {
  //   footer style
  .footer-wrap {
    padding: 3rem 5px !important;
    padding-bottom: 1rem !important;
    .footer-listing {
      .email-us {
        padding: 4px 9px;
        img {
          width: 33px;
        }
      }
      .call-us {
        padding: 4px 9px !important;
        img {
          margin-left: 0px;
        }
      }
    }
    .copy-right-wrapper {
      P {
        text-align: center;
        line-height: 20px;
      }
    }
  }
  .footer-banner-wrap.home-footer-banner-bg {
    height: 100%;
  }
}

//extra large devices
@media only screen and (min-width: 1250px) and (max-width: 1440px) {
  .footer-banner-wrap {
    &.home-footer-banner-bg {
      background-size: cover;
      height: 100%;
    }
    .left-title-wrap {
      h3 {
        font-size: 27px;
      }
    }
    .right-side-wrapper {
      img {
        width: 60%;
      }
    }
  }
}
