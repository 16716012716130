.personal-banner{
    background: #EEFFF6;
    padding: 5rem 0px;
    height: 465px;
    z-index: 1;
    position: relative;
    .personal-detail-wrapper{
        background: #FFFFFF;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
        width: 60%;
        margin: auto;
        padding: 65px;
        margin-top: 6rem;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            background-image: url(./../../assets/images/person-left-bg.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            top: -45px;
            left: -90px;
            width: 145px;
            height: 260px;
            z-index: -1;
        }
        &::after{
            content: '';
            position: absolute;
            background-image: url(./../../assets/images/person-right-bg.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            bottom: 12px;
            right: -75px;
            width: 145px;
            height: 260px;
            z-index: -1;
        }

        .left-img-wrap{
         width: 100%;
         height: auto;
        }
        .detail-wrap{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            h5{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                color: #84BC41;
            }
            h3{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 900;
                font-size: 36px;
                line-height: 141%;
                color: #000000;
            }
            p{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 200%;
                color: #000000;
            }
            .social-icon{
                margin-top: 30px;
                ul{
                    list-style-type: none;
                    padding: 0px;
                    margin: 0px;
                    display: flex;
                    align-items: center;
                    gap: 18px;
                    li{
                        width: 45px;
                        height: 45px;
                        background: #162C4E;
                        display: grid;
                        place-content: center;
                       img{
                        width: 19px;
                        height: auto;
                       }
                    }
                }
            }
        }

    }
}
//about us 
.person-about-us{
    margin-top: 22rem;
    margin-bottom: 4rem;
    .about-main{
        h3{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 900;
            font-size: 28px;
            line-height: 141%;
            color: #000000;
        }
        p{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 200%;
            color: #000000;
        }
        ol{
            li{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 200%;
                color: #000000;
            }
        }
    }
}

//for mobile devices 
@media only screen and (min-width: 320px) and (max-width: 520px) {
    .personal-banner{
        height: 100%;
        .personal-detail-wrapper{
            width: 90%;
            padding: 10px;
            &::after{
             display: none;
            }
            .detail-wrap{
                padding-top: 15px;
                .social-icon{
                    margin-top: 0px;
                }
            }
        }
    }
    .person-about-us{
        margin-top: 2rem;
        margin-bottom: 2rem;
        .about-main{
            h3{
                font-size: 25px;
            }
        }
    }
}

//medium deviecs
@media only screen and (min-width: 521px) and (max-width: 767px) {
    .personal-banner{
        height: 100%;
        .personal-detail-wrapper{
            width: 60%;
            padding: 10px;
            &::after{
             
            }
            .detail-wrap{
                padding-top: 15px;
                .social-icon{
                    margin-top: 0px;
                }
            }
        }
    }
    .person-about-us{
        margin-top: 2rem;
        margin-bottom: 2rem;
        .about-main{
            h3{
                font-size: 25px;
            }
        }
    }
}

/*For Ipad or tablet*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .personal-banner{
        height: 100%;
        .personal-detail-wrapper{
            width: 60%;
            padding: 10px;
            .detail-wrap{
                padding-top: 15px;
                .social-icon{
                    margin-top: 0px;
                }
            }
        }
    }
    .person-about-us{
        margin-top: 2rem;
        margin-bottom: 2rem;
        .about-main{
            h3{
                font-size: 25px;
            }
        }
    }
}

//large devices
@media only screen and (min-width: 991px) and (max-width: 1250px) {}