.home-main-banner {
    background: #162c4e;
    padding: 60px 24px 0;
    height: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    img {
        max-width: 350px;
        height: fit-content;
        object-fit: contain;
    }

    @media (max-width: 768px) {
        padding: 24px;
        img {display: none;}
    }

    .text-area {
        padding: 12px;

        h2 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 900;
            font-size: 32px;
            line-height: 150%;
            color: white;
        }

        p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 30px;
            color: white;
        }
    }
}