.navbar-wrap {
    position: fixed;
    width: 100%;
    top: 0px;
    height: 65px;
    transition: all 0.3s ease-in-out;
    z-index: 99;
    background-color: #eefff6;
    .navbar-brand {
        img {
            width: 100%;
            height: auto;
        }
    }

    .navbar-nav {
        .nav-link {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 12px;
            color: #162C4E;
            margin: 0px 12px;
            &.tag-line{
                color:  #162C4E;
                font-size: 14px;
                line-height: 29px;
            }
            a{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #162C4E;
                text-decoration: none;
            }
            .active{
                color: #84BC41;
            }
        }
        .dropdown-menu{
            .dropdown-item{
                a{
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    color: #162C4E;
                    text-decoration: none;  
                }
                .active{
                    color: #84BC41;
                }
            }
        }
        .get-started-btn{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 800;
            font-size: 15px;
            line-height: 15px;
            color: #002C72;
            background-color: #fff;
            border-radius: 0px;
            border: 0px;
            transition: all 0.4s ease-in-out;
            &:hover{
                background-color: #84BC41;
                color: #fff;
            }
        }
    }
}
.sticky{
    box-shadow: 0px 2px 5px 0px #ccccccd6;
    background-color: #fff;
    .tag-line{
        color: #162C4E!important;
        font-size: 14px;
        line-height: 29px;
    }
    .get-started-btn{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 15px;
        line-height: 15px;
        color: #fff!important;
        background-color: #002C72!important;
        border-radius: 0px;
        border: 0px;
        transition: all 0.5s ease;
        &:hover{
            background-color: #84BC41!important;
            color: #fff!important;
        }
    }
}
.navbar-toggler{
    &:focus{
        box-shadow: unset;
    }
}

//responsive navbar style  till tablets 
@media only screen and (min-width: 320px) and (max-width: 991px) {
    .navbar-collapse {
        background-color: #EEFFF6;
        padding: 10px;
        margin-top: 17px;
        .navbar-nav {
            align-items: baseline;
            .tag-line {
                color: #082d4e!important;
            }

            .get-started-btn {
                background-color: #71be4a;
                color: #fff;
                line-height: 30px;
            }
        }
    }
    .navbar-wrap{
        .container{
            max-width: 100%;
            padding: 0px;
            .navbar-brand{
                margin-left: 10px;
            }
            .navbar-toggler{
                margin-right: 10px;
            }
        }
    }
}