// footer banner section starts
.footer-banner-wrap {
  padding: 3rem 10rem;
  &.home-footer-banner-bg {
    background-image: url(./../../assets/images/home-footer-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .left-title-wrap {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    height: 355px;
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 141%;
      color: #ffffff;
      margin-bottom: 40px;
    }

    .get-started-btn {
      font-family: Montserrat;
      background: #fff;
      line-height: 37px;
      padding: 5px 17px;
      border-color: #fff;
      font-size: 14px;
      border-radius: 0px;
      font-weight: 600;
      color: #162c4e;
    }
  }
  .right-side-wrapper {
    img {
      position: relative;
      width: 50%;
      height: auto;
    }
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1440px) {
  .footer-banner-wrap {
    &.home-footer-banner-bg {
      height: 100%;
    }
    .left-title-wrap {
      h3 {
        font-size: 27px;
      }
    }
    .right-side-wrapper {
      img {
        width: 50%;
      }
    }
  }
}
